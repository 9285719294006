export default {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  name: "Go Legal India",
  legalName: "Go Legal India",
  image: "https://golegalindia.com" + require("../../images/Logo.webp"),
  logo: "https://golegalindia.com" + require("../../images/Logo.webp"),
  description:
    "Go Legal India is an online legal service provider. We cover all your business registration needs in one place with over 10,000 satisfied customers.",
  openingHours: "Mo-Su 09:00-18:00",
  address: {
    "@type": "PostalAddress",
    streetAddress: "33H/3 Mominpore Road",
    addressLocality: "Kolkata",
    addressRegion: "West Bengal",
    postalCode: "700023",
    addressCountry: "IN",
  },
  telephone: "+91 74397 75535",
  url: "https://golegalindia.com/",
  hasService: [
    {
      "@type": "Service",
      name: "FSSAI Registration",
      description: "Obtain the necessary license for your food business.",
      url: "https://golegalindia.com/fssai-registration",
    },
    {
      "@type": "Service",
      name: "Trademark Registration",
      description: "Protect your brand name and logo.",
      url: "https://golegalindia.com/trademark-registration",
    },
    {
      "@type": "Service",
      name: "Tax & Compliance",
      description: "File your GST returns and income tax returns.",
      url: "https://golegalindia.com/gst-registration",
    },
    {
      "@type": "Service",
      name: "Drafting & Filing",
      description:
        "Get legal documents drafted and filed by experienced professionals.",
      url: "https://golegalindia.com/income-tax-filing",
    },
  ],
};
