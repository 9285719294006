import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaUser } from "react-icons/fa";
import HomeBanner from "../../assets/images/home/banner_2.webp";
import { NavLink } from "react-router-dom";
import { companyName } from "../../Config/config";
function HomeIntro() {
  return (
    <div
      style={{
        backgroundImage: `url(${HomeBanner})`,
        backgroundPosition: "center",
      }}
      className="parallax-home lg:h-[620px] lg:flex items-center justify-center"
    >
      <div className="bg-black lg:h-[620px] bg-opacity-50 px-6 py-16 shadow-lg text-white w-full">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-[60%] lg:p-20">
            <span className="text-3xl font-bold mb-4 text-left">Welcome to</span>
            <h1 className="text-5xl font-bold mb-4 text-left">
              {companyName}
            </h1>
            <span className="text-3xl font-bold mb-4 text-left">
              India's Most Trusted Online Portal
            </span>
            <h2 className="text-2xl text-left mb-4">
              {companyName} is Online Legal Service Provider. We got you
              covered with all your business registration at one place. With
              10000+ Satisfied Customers with 4.5/5.0 Google Rating.
            </h2>
            <NavLink
              className=" form-button p-3 mt-50 text-sm font-bold text-white bg-green-800 rounded-md"
              to={"/contact-us"}
            >
              Get Started
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeIntro;
