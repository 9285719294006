export default {
  "@context": "http://schema.org/",
  "@type": "Product",
  name: "Go Legal India",
  description:
    "Go Legal India provides all kinds of licenses and registrations like Trademark, FSSAI, GST etc. 2.5 lakhs+ satisfied clients.",
  aggregateRating: {
    "@type": "AggregateRating",
    ratingValue: "4.96",
    bestRating: "5",
    worstRating: "1",
    ratingCount: "6184",
  },
  image:
    "https://golegalindia.com" + require("../../images/Logo/LogoSquare.webp"),
  keywords:
    "Trademark, Company Registration, FSSAI Registration, Trademark Registration",
  brand: "Go Legal India",
};
