import React from "react";
import { useLocation } from "react-router-dom";
import TopBannerSection from "../../components/TopBannerSection";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { HiDocumentArrowDown } from "react-icons/hi2";
import { FaCheck } from "react-icons/fa";
import themeStyle from "../../styles/theme.style";
import ProcessRoundCircleSection from "../../components/ProcessRoundCircleSection";
import OverViewImage from "../../assets/images/Trademark & IP/trademark-opposition-overview.webp";
import {
  TrademarkOppositionBenefit,
  TrademarkOppositionDocumentsRequired,
  TrademarkOppositionOverview,
  TrademarkOppositionRequirement,
  TrademarkOppositionTypes,
} from "./TrademarkTabContent/TrademarkOppositionContent";
import { TrademarkCopyrightOverview } from "./TrademarkTabContent/TrademarkCopyrightContent";
import OverviewContent from "../../components/OverviewContent";
import CustomTabs from "../../components/CustomTabs";
import PageFaq from "../../components/PageFaq";
const TrademarkOpposition = () => {
  const location = useLocation();
  const process = [
    {
      title: "Reach out to our Experts",
      icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />,
      description:
        "We have the best business experts who can resolve all your queries",
    },
    {
      title: "Provide all the required documents",
      icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />,
      description:
        "Provide all the documents.Our team will initiate the paperwork on your behalf",
    },
    {
      title: "Get your Copyright done",
      icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />,
      description:
        "Trademark Opposition is completed and the certificates will be sent to you.",
    },
  ];
  const tabs = [
    { title: "Benefit", content: <TrademarkOppositionBenefit /> },
    { title: "Requirement", content: <TrademarkOppositionRequirement /> },
    { title: "Types", content: <TrademarkOppositionTypes /> },
    {
      title: "Document required",
      content: <TrademarkOppositionDocumentsRequired />,
    },
  ];
  const faqs = [
    {
      title: "What is Trademark Opposition?",
      content:
        "An individual who thinks that his or her brand name or the reputation of the company will be damaged by the registration of a mark can raise a trademark opposition.",
    },
    {
      title: "What is the period for filing trademark opposition?",
      content:
        "The Opposition notice can be filed within three months and can be extended by one month from the date which the trademark was published in the trademark journal.",
    },
    {
      title: "Who can oppose a Trademark?",
      content:
        "Anyone can file for trademark opposition, but usually, an opposition is raised by a person who is the owner of the trademark or a mark that deals with similar goods and services.",
    },
  ];
  return (
    <div>
      <TopBannerSection location={location.pathname} />
      <ProcessRoundCircleSection
        heading={"How To do Your Trademark Opposition through GLI"}
        process={process}
      />
      <OverviewContent
        OverViewImage={OverViewImage}
        content={<TrademarkOppositionOverview />}
      />
      <CustomTabs tabs={tabs} />
      <PageFaq list={faqs} />
    </div>
  );
};

export default TrademarkOpposition;
