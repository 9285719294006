import React from "react";
import { NavLink } from "react-router-dom";
import SidebarSubmenu from "./SidebarSubmenu";
import {
  isPageEnabled,
  navTitles,
  pagePaths,
  serviceTitles,
} from "../Config/pagePaths";

function Sidebar({ sidebarOpen, toggleSidebar }) {
  const myMenu = [];
  Object.keys(serviceTitles).map((item, index) => {
    const data = {
        title:item,
        submenu: [
        ]
    }
    serviceTitles[item].map((TitleItem,titleIndex)=>{
        Object.keys(pagePaths).map((pathItem,PathIndex)=>{
            if(pagePaths[pathItem]==TitleItem){
                data.submenu.push({
                    title:navTitles[pathItem],
                    navigation:TitleItem
                })
            }
        })
    })
    myMenu.push(data)
  });

  return (
    <div
      className={`fixed inset-0 z-50 transition-transform duration-300 ease-in-out transform ${
        sidebarOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="fixed inset-y-0 right-0 w-64 bg-white p-5 shadow-lg">
        <div className="flex justify-between items-center mb-5">
          <img
            src={require("../assets/images/Logo.webp")}
            width={100}
            height={100}
          />
          <button
            onClick={toggleSidebar}
            className="text-black text-xl font-bold"
          >
            &times;
          </button>
        </div>
        <nav>
          <NavLink
            className="block text-black text-md font-semibold py-2"
            to={"/"}
            onClick={toggleSidebar}
          >
            Home
          </NavLink>
          <SidebarSubmenu list={myMenu} toggleSidebar={toggleSidebar} />
          <NavLink
            className="block text-black text-md font-semibold py-2"
            to={"/about-us"}
            onClick={toggleSidebar}
          >
            About Us
          </NavLink>
          <NavLink
            className="block text-black text-md font-semibold py-2"
            to={"/contact-us"}
            onClick={toggleSidebar}
          >
            Contact Us
          </NavLink>
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
