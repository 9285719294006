import React from "react";
import { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const SetIndiaBizHelpsContainer = ({ image, count, title, isTriggered }) => {
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  return (
    <div className="grid justify-items-center bg-red-50 py-4 rounded-xl border border-red-600 gap-2 transition ease-in-out delay-100 hover:scale-110 duration-250">
        <div className="text-5xl font-bold">
          <CountUp
            key={`${isTriggered}`}
            className=""
            start={count / 2}
            end={count + getRandomInt(499, 999)}
            duration={2}
            delay={0}
          />
          +
        </div>
        <p className="font-semibold text-xl text-red-500">{title}</p>
        <img src={image} alt={title} className="w-1/4" />
    </div>
  );
};

export default SetIndiaBizHelpsContainer;
