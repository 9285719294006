import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import { HiDocumentArrowDown } from 'react-icons/hi2'
import { FaCheck } from 'react-icons/fa'
import themeStyle from '../../styles/theme.style'
import CustomTabs from '../../components/CustomTabs'
import { TrademarkBenefit, TrademarkDocumentRequired, TrademarkOverview, TrademarkRequirement, TrademarkTypes } from './TrademarkTabContent/TrademarkRegistrationContent'
import PageFaq from '../../components/PageFaq'
import OverviewContent from '../../components/OverviewContent'
import BackgroundImage from "../../assets/images/Trademark & IP/TrademarkRegistration.webp"
import OverViewImage from "../../assets/images/Trademark & IP/TrademarkRegistration OverView.webp";
import { useLocation } from 'react-router-dom'
import TrademarkCertificate from '../SampleCertificate/TrademarkCertificate'
function TrademarkRegistration() {
  const topImageUrl = "https://st3.depositphotos.com/30987588/34891/v/450/depositphotos_348913014-stock-illustration-people-in-cozy-cafe-coffee.jpg";
  const data = ['Protect Your Brand, Logo & Tagline.', 'More Than 10k Brand Registered Since 2020.', 'Use ™ In 30 Mins.','Same Day Process.','100% Online Process.']

  const process = [
    { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
    { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
    { title: 'Get your Trademark Registration done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'Trademark Registration is completed and the certificates will be sent to you.' },
  ]

  const tabs = [
    // { title: "Overview", content: <TrademarkOverview /> },
    { title: "Benefit", content: <TrademarkBenefit /> },
    { title: "Requirement", content: <TrademarkRequirement /> },
    { title: "Types", content: <TrademarkTypes /> },
    { title: "Document required", content: <TrademarkDocumentRequired /> },
  ];

  const faqs = [
    {
      title: 'What is a trademark?',
      content: 'A trademark is a distinctive sign that identifies and distinguishes the goods or services of one business from those of others. It can be a word, logo, symbol, slogan, or a combination thereof.',
    },
    {
      title: 'Why should I register a trademark?',
      content: 'Trademark registration provides legal protection and exclusive rights to use the mark in connection with specific goods or services. It helps prevent others from using similar marks that could cause confusion or dilution of your brand.',
    },
    {
      title: 'What is the difference between ™ and ®?',
      content: '“TM” stands for trademark and can be used to indicate that you’re claiming rights to a mark, even if it’s not registered. “®” symbolizes a registered trademark, indicating that the mark is officially registered.',
    },
    {
      title: 'Do I need a lawyer to register a trademark?',
      content: 'While it’s possible to register a trademark without a lawyer, legal assistance is recommended. Trademark attorneys can help with proper application submission, searching for conflicting marks, and navigating potential issues.',
    },
    {
      title: 'What is a trademark search?',
      content: 'A trademark search involves checking existing trademarks to determine if your proposed mark is already in use. This helps you avoid conflicts and increase the chances of successful registration.',
    },
    {
      title: 'Can I trademark a common word?',
      content: 'It’s possible to trademark common words if they are used in a unique and distinctive manner in relation to specific goods or services. Generic or highly descriptive terms might face challenges.',
    },
    {
      title: 'Can a domain name serve as a trademark?',
      content: 'A domain name can also be a trademark if it meets the requirements for distinctiveness and is used to identify goods or services. However, not all domain names are automatically eligible for trademark protection.',
    },
    {
      title: 'What happens after trademark registration?',
      content: 'After successful registration, you gain exclusive rights to use the mark for the specified goods or services. It’s your responsibility to monitor and enforce your trademark against potential infringers.',
    },
    {
      title: 'Can trademarks be renewed?',
      content: 'Yes, trademarks can be renewed for additional periods, often every 10 years. Regular renewal and payment of renewal fees are required to maintain trademark protection.',
    },
    {
      title: 'What is trademark infringement?',
      content: 'Trademark infringement occurs when someone uses a mark that is confusingly similar to a registered mark in a way that could lead to consumer confusion about the source of goods or services.',
    },
  ];
  const location = useLocation();
  return (
    <div>
      <TopBannerSection location={location.pathname} BackgroundImage={BackgroundImage} heading={'Trademark Registration'} lineThrough={'₹2499'} boldNumber={'₹999 + Govt Fees'} list={data ?? []} />
      <ProcessRoundCircleSection heading={'How To Do Trademark Registration Through "GLI"'} process={process} />
      <OverviewContent OverViewImage={OverViewImage} content={ <TrademarkOverview /> }/>
      <CustomTabs tabs={tabs} />
      <TrademarkCertificate />
      <PageFaq list={faqs} />
    </div>
  )
}

export default TrademarkRegistration