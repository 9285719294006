import React from "react";
import ErrorPageNotFound from "../../assets/images/NotFound/404ErrorPageNotFound.webp";
const PageNotFound = () => {
  return (
    <div className="flex justify-center my-28 md:my-0">
      <img src={ErrorPageNotFound} alt="Oops!, Page Not Found" className="w-1/2" />
    </div>
  );
};

export default PageNotFound;
