import React, { useEffect, useRef, useState } from "react";
import "./FssaiRegistration.css";
import SetIndiaBizForm from "../../../../components/SetIndiaBiz/SetIndiaBizForm";
import TopBarHeader from "../../../../components/SetIndiaBiz/TopBarHeader";
import { API_END_POINT, companyName } from "../../../../Config/config";
import { useLocation, useNavigate } from "react-router-dom";
import SetIndiaBizPlans from "../../../../components/SetIndiaBiz/SetIndiaBizPlans";
import SetIndiaBizOverview from "../../../../components/SetIndiaBiz/SetIndiaBizOverview";
import SetIndiaBizWidget from "../../../../components/SetIndiaBiz/SetIndiaBizWidget";
import SetIndiaBizRegistrationProcess from "../../../../components/SetIndiaBiz/SetIndiaBizRegistrationProcess";
import SetIndiaBizDocumentsRequired from "../../../../components/SetIndiaBiz/SetIndiaBizDocumentsRequired";
import SetIndiaBizDocumentRequiredFssaiRegistration from "../../../../assets/Data/SetIndiaBizDocumentRequiredFssaiRegistration.json";
import SetIndiaBizTimelineFssaiRegistration from "../../../../assets/Data/SetIndiaBizTimelineFssaiRegistration.json";
import SetIndiaBizPenaltyFSSAI from "../../../../assets/Data/Fssai/SetIndiaBizPenaltyFSSAI.json";
import SetIndiaBizHowWeHelp from "../../../../assets/Data/Fssai/SetIndiaBizHowWeHelp.json";
import SetIndiaBizPenalty from "../../../../components/SetIndiaBiz/SetIndiaBizPenalty";
import PageFaq from "../../../../components/PageFaq";
import SetIndiaBizLine from "../../../../components/SetIndiaBiz/SetIndiaBiz.Line";
import { useSelector } from "react-redux";
import { updateMetaDescription } from "../../../../Config/functions";
import FssaiCertificate from "../../../../Pages/SampleCertificate/FssaiCertificate";
import IMAGE_2 from "../../../../assets/images/fssaiUpdate.webp";
import Helping_1 from "../../../../assets/images/Helping/delivery.webp";
import Helping_2 from "../../../../assets/images/Helping/indian-man.webp";
import Helping_3 from "../../../../assets/images/Helping/restaurant-building.webp";
import SetIndiaBizHelpsBusiness from "../../../../components/SetIndiaBiz/SetIndiaBizHelpsBusiness/SetIndiaBizHelpsBusiness";
import TalkToExpertForm from "../../../../components/TalkToExpertForm";
import MetaManager from "../../../../components/MetaManager";
import metaData from "../../../../Config/metaData";
const FssaiRegistration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const reduxData = useSelector((state) => state);
  const [defaultPlan, setDefaultPlan] = useState({});
  const [data, setData] = useState({});
  const [plansList, setPlansList] = useState([]);
  const getPlanDetails = async () => {
    const API = `${API_END_POINT}Plans/getPlanDetails.php?plan=${encodeURI(
      location.pathname.replace(/^\/+/, "").replace(/\/+$/, "").split("/").pop()
    )}`;
    const response = await fetch(API);
    const resJson = await response.json();
    resJson.map((item) => {
      if (item.id == item.recommended_plan) {
        setDefaultPlan(item);
      }
    });
    setPlansList(resJson);
  };
  const Helps = [
    {
      image: Helping_1,
      title: "Cloud Kitchens",
      count: 1450,
    },
    {
      image: Helping_2,
      title: "Dhaba",
      count: 1450,
    },
    {
      image: Helping_3,
      title: "Restaurants",
      count: 1450,
    },
    ,
  ];
  const faqs = [
    {
      title:
        "Can FSSAI registration be transferred from one person to another?",
      content:
        "No, FSSAI registration is specific to the person or entity that applied for it. It is non-transferable.",
    },
    {
      title:
        "Can I apply for FSSAI registration if I have multiple food businesses at different locations?",
      content:
        "Yes, you can apply for a single FSSAI registration to cover multiple locations of the same food business within the same state. However, separate licenses are required if the locations are in different states.",
    },
    {
      title: "What is the penalty for non-compliance with FSSAI regulations?",
      content:
        "Penalties for non-compliance can include fines, imprisonment, cancellation of license, and closure of the food business. The severity of the penalty depends on the nature and extent of the violation.",
    },
    {
      title: "How much time does it take to get the certificate?",
      content:
        "Minimum 3 days maximum 60 days its total depends on state government Fssai Officers.",
    },
  ];
  function formatService(companyName) {
    // Remove the leading slash if it exists
    if (companyName?.startsWith("/")) {
      companyName = companyName?.substring(1);
      companyName = `${companyName}`?.replace("/", "");
    }

    return companyName;
  }
  const formattedService = formatService("fssai-registration");
  const getContent = async () => {
    if (reduxData?.webData) {
      document.title =
        reduxData?.webData[formattedService]?.title + " - " + companyName;
      setData(reduxData?.webData[formattedService] || {});
    }
  };
  useEffect(() => {
    getPlanDetails();
  }, []);
  useEffect(() => {
    getContent();
  }, [reduxData]);
  return (
    <main className={`cursor-default ${reduxData?.webData ? "" : "hidden"}`}>
      {reduxData?.webData && (
        <div className="header-background px-5 sm:px-10 md:px-20 py-5 flex flex-col md:flex-row justify-between gap-x-10">
          <MetaManager
            title={reduxData?.webData[formattedService]?.title}
            description={metaData["fssai-registration"].description}
          />
          <TopBarHeader
            title={reduxData?.webData[formattedService]?.title}
            subTitle={"Obtain FSSAI Registration Certificate"}
            description={metaData["fssai-registration"].description}
          />
          <TalkToExpertForm
            planPathName={defaultPlan?.service_type}
            heading={reduxData?.webData[formattedService]?.form_title}
          />
        </div>
      )}
      <div className="w-full flex flex-col justify-center mb-4">
        <img src={IMAGE_2} alt="FSSAI Update" className="w-5/6 sm:w-4/6 self-center" />
      </div>
      {plansList.length > 0 && (
        <SetIndiaBizPlans
          plans={plansList}
          title="Our Professional Fee For FSSAI Registration of License"
        />
      )}
      <section>
        <FssaiCertificate />
      </section>
      {/* side widget */}
      <section className="">
        <SetIndiaBizOverview
          title={`FSSAI Registration for FBO Overview`}
          contentComponent={
            <>
              <p className="my-5">
                Food is a vital sector for our economy, being an essential
                commodity for life. Everyone needs it. It is a well-settled rule
                that the demand for an item gives rise to business
                opportunities. Thus, many petty food manufacturers like Hawkers,
                a temporary stallholder or itinerant vendor or a small FBO
                carrying out its business within the state and having an annual
                turnover below 12 lacs. All these businesses need to register
                themselves with FSSAI before the commencement of their business.
                Food registration is mandatory for these petty food
                manufacturers and small FBOs to regulate the quality of food
                products and ensure that FBOs could provide healthy, wholesome
                and hygienic food to all.
              </p>
              <p className="my-5">
                <strong>FSSAI Registration:</strong>&nbsp;The Food registration
                is the basic registration with FSSAI of a FBO. It is required by
                petty food manufacturers or the FBO having a turnover of less
                than Rs 12 Lakhs unless the FBO is covered under mandatory state
                or central licensing.
              </p>
              <p className="my-5">
                Food registration, once granted, shall be valid for the period
                opted at the time of making an application. The FBO may opt for
                a validity period from one year to five years. On successful
                grant of food registration, the Registering Authority shall
                issue the FSSAI Registration Certificate and a photo identity
                card. These need to be displayed at a place visible to all
                coming to the vehicle or cart or premises or any other place
                where the person carries on sale/manufacture of food in Petty
                Food Business. Once an FBO gets a registration certificate from
                FSSAI, it can start its business in compliance with the
                conditions mentioned in the registration certificate. Every FBO
                granted a registration certificate must follow general hygienic
                and sanitary practices.
              </p>
            </>
          }
          customContentClass={`text-justify`}
          footerComponent={
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <SetIndiaBizWidget
                title={
                  <h3 className="font-bold text-xl text-center md:text-left">
                    Annual Return
                  </h3>
                }
                customComponentClass={`my-3 bg-red-100 py-2 rounded gap-4 items-center border border-red-500`}
                content={
                  <p className="text-center md:text-left">
                    No annual return filing is required in case of FSSAI
                    Registration.
                  </p>
                }
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-10 h-10 bg-white rounded-full p-0"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                    />
                  </svg>
                }
              />
              <SetIndiaBizWidget
                title={
                  <h3 className="font-bold text-xl text-center md:text-left">
                    Exemptions
                  </h3>
                }
                customComponentClass={`my-3 bg-red-100 py-2 rounded gap-4 items-center border border-red-500`}
                content={
                  <p className="text-center md:text-left">
                    Those producers of milk are granted exemption from Food
                    registration who supplies or sells the whole milk to dairy
                    Cooperative Society registered under Cooperative Societies
                    Act and is a registered member of it.
                  </p>
                }
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-10 h-10 bg-white rounded-full p-0"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                }
              />
            </div>
          }
        />
        <SetIndiaBizHelpsBusiness array={Helps} />
        <SetIndiaBizRegistrationProcess
          title={"FSSAI Registration Process in India"}
          checkListArray={[
            {
              title: "Eligibility Check",
              description:
                "The process starts with the eligibility check of the applicant FBO. In this, the experts at Go Legal India will discuss the nature of the proposed business plan in detail and help you find your eligibility as per your proposed requirements.",
            },
            {
              title: "Documentation & Drafting",
              description:
                "Once it is determined that you need food registration. Go Legal India Team will provide you with a list of documents. Based on the requirement at Go Legal India team will help you with draft documents for the necessary signature.",
            },
            {
              title: "Online Application Filing",
              description:
                "The online filing will be made by filing Form-A at the online portal of FoSCoS. In this course, we will interact with you for OTP.",
            },
            {
              title: "Payment of Government Fee",
              description:
                "Once the application is filled and the documents are uploaded in the document section, the payment of the registration fee is made, and a reference number is generated. It is a temporary number to track the status of the application.",
            },
            {
              title: "Track status of FSSAI Application",
              description:
                "The applicant can track the status of the application online. Usually, licencing authorities grant registration within seven days of the application filing, but if they opine for inspection of premises, it will delay the grant of Food registration. On inspection, registering authority may raise the clarification in the application requiring modification, addition or deletion in the application.",
            },
            {
              title: "Reply to Clarification",
              description:
                "The reply to clarification must be filed within 30 days from the date when raising clarification. If not, this will lead to refusal of the application, and a new application needs to be filed. There is no process of refunding the fee. Go Legal India Team will coordinate with you at the time of such clarification to resolve the same.",
            },
            {
              title: "Grant of Registration",
              description:
                "On approval of the application, the Registration Certificate and photo ID Card will be issued to the applicant, which has to be displayed at a conspicuous place at the food business premises. Registration certificate has a photo FBO, Q.R. code and 14 digits FSSAI registration number that starts with numeric 2.",
            },
          ]}
          customClass={``}
        />
        <SetIndiaBizDocumentsRequired
          title={"Documents Required for FSSAI Registration in India"}
          description={
            <>
              The applicant of a food registration could be a proprietorship
              firm or any other entity like Private Limited Company, LLP,
              Partnership Firm, Trust/Society etc. The documentation will vary
              as per the type of applicant, kindly select the appropriate
              application type to display the List of{" "}
              <a
                title="documents required for FSSAI registration"
                href="#"
                target="_blank"
                rel="noopener"
              >
                documents required for FSSAI registration
              </a>
              .
            </>
          }
          documentsType={"FSSAI Registration"}
          documentsTypeList={SetIndiaBizDocumentRequiredFssaiRegistration.types}
          selectedDocumentsTypeIndex={3}
          documents={SetIndiaBizDocumentRequiredFssaiRegistration.documents}
          // notes={}
        />
        <SetIndiaBizOverview
          title={"Validity of FSSAI Registration"}
          contentComponent={
            "A Food Registration, once granted, shall be valid for a period of 1 to 5 years as chosen by the Food Business Operator unless suspended or cancelled by the Licencing Authority. The period of validity is counted from the date of issue of the food registration certificate. It can be renewed for a further period, a maximum of five years, at one time not later than 30 days before the expiry of the validity of the registration certificate. There is no late fee in the renewal of registration as per the FSSAI notification dated 10th June 2014."
          }
          customComponentClass={"mb-5"}
        />
        <SetIndiaBizOverview
          title={"Timeline for Grant of FSSAI Registration"}
          contentComponent={
            "Timeline for grant of Registration Certificate after making an application with the registration authority will depend on whether the registration authority wants to inspect the applicant’s premise. The timelines are as under:"
          }
          footerComponent={SetIndiaBizTimelineFssaiRegistration.map(
            (item, index) => (
              <div className="flex items-center gap-3 mt-2">
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-red-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                    />
                  </svg>
                </div>
                <span key={index.toString()}>{item}</span>
              </div>
            )
          )}
          customComponentClass={"mb-5"}
        />
        <SetIndiaBizPenalty
          title={"Penalty Under FSSAI"}
          contentComponent={
            "All the FBO, irrespective of their scale of operation, kind of food business including home-based kitchens manufacturing food and bakery products whose annual turnover is less than 12 lacs is required to take food registration before the commencement of food business. If the FBO is not registered, a fine up to Rs 5 lacs and imprisonment of 6 months could be imposed depending upon the offences committed by it. The punishment or penalty will vary depending upon the scale of operation and the nature of offences. The offences and the fine prescribed is as under"
          }
          tableObject={SetIndiaBizPenaltyFSSAI}
        />
        <SetIndiaBizOverview
          title={"How We Help You With FSSAI Registration!"}
          footerComponent={SetIndiaBizHowWeHelp.map((item, index) => (
            <p className="border rounded-lg p-3 my-3">
              <span className="text-nowrap font-bold mr-2 gap-2">
                <span>{index + 1}. </span>
                <span>{item.title}</span>:
              </span>
              <span>{item.description}</span>
            </p>
          ))}
        />
        <section className={`px-5 sm:px-10 md:px-20 pt-10`}>
          <h3 className="font-semibold text-2xl md:text-4xl">{"FAQ"}</h3>
          <SetIndiaBizLine />
          <PageFaq list={faqs} AccordionOnly />
        </section>
      </section>
    </main>
  );
};

export default FssaiRegistration;
