import React from 'react'
import TopBannerSection from '../../components/TopBannerSection'
import ProcessRoundCircleSection from '../../components/ProcessRoundCircleSection'
import { FaCheck } from 'react-icons/fa'
import { HiDocumentArrowDown } from 'react-icons/hi2'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import themeStyle from '../../styles/theme.style'
import CustomTabs from '../../components/CustomTabs'
import { NGOBenefit, NGODocuments, NGOOverview, NGORequirement, NGOTypes } from './NGOTabContent'
import PageFaq from '../../components/PageFaq'
import OverviewContent from '../../components/OverviewContent'
import BackgroundImage from "../../assets/images/Private Limited Company/banner.webp"
import OverViewImage from "../../assets/images/Private Limited Company/Over View.webp";
import { useLocation } from 'react-router-dom'
function NGORegistration() {
  const topImageUrl = "https://st3.depositphotos.com/30987588/34891/v/450/depositphotos_348913014-stock-illustration-people-in-cozy-cafe-coffee.jpg";

  const data = ['Register a Section 8 Company - Setup a Not for Profit Entity with Us.', 'Quick and easy process within 7 days Registration', 'We help you with the post-registration formalities and compliances.']

  const process = [
    { title: 'Reach out to our Experts', icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />, description: 'We have the best business experts who can resolve all your queries' },
    { title: 'Provide all the required documents', icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />, description: 'Provide all the documents. Our team will initiate the paperwork on your behalf' },
    { title: 'Get your NGO Registration done', icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />, description: 'NGO Registration is completed and the certificates will be sent to you.' },
  ]

  const tabs = [
    // { title: "Overview", content: <NGOOverview /> },
    { title: "Benefit", content: <NGOBenefit/> },
    { title: "Requirement", content: <NGORequirement/> },
    { title: "Types", content: <NGOTypes/>},
    { title: "Document required", content: <NGODocuments/> },
  ];

  const faqs = [
    {
      title: 'Is thereany maximum limit for the number of members in NGO?',
      content: 'There is no such maximum limit.',
    },
    {
      title: 'Can any government employees or officer be a member of NGO ?',
      content: 'The answer to this question is yes. Government employees or officers can be part of NGOs provided the NGO is not anti-government. There are a few rules too that these people have to follow, one of them is to make sure that the NGO is not profit making and the member must not draw any salary from the NGO.',
    },
    {
      title: 'How much capital is required to start an OPC ?',
      content: 'There is no difference in capital requirement between an OPC and a private limited company. It needs an authorized capital of ₹1 lakh, to begin with, but none of this actually needs to be paid up. This means that you don’t really need to invest any money into the business.',
    },
    {
      title: 'What are the other alternatives for the starting an NGO ?',
      content: 'The other alternatives are to start a Trust or a Society based on the objectives of your NGO.',
    },
    {
      title: 'How is the property of NGO managed ?',
      content: 'The property of the company vests in the name of the Company and the same can be sold as per the rules mentioned under the Companies Act.',
    },
  ];

  const location = useLocation();
  return (
    <div>
      <TopBannerSection location={location.pathname} BackgroundImage={BackgroundImage} heading={'NGO Registration In India'} lineThrough={'₹15999'} boldNumber={'₹8999'} list={data ?? []} />
      <ProcessRoundCircleSection heading={'How To Do Your NGO Registration Through "GLI"'} process={process} />
      <OverviewContent OverViewImage={OverViewImage} content={ <NGOOverview /> }/>
      <CustomTabs tabs={tabs} />
      <PageFaq list={faqs}/>
    </div>
  )
}

export default NGORegistration