export default {
  "fssai-registration": {
    description:
      "Get your FSSAI Registration online with Go Legal India. We help you obtain the certificate and file your application on the FOSCOS Portal. Call us for assistance today!",
  },
  "fssai-renewal": {
    description:
      "Renew your FSSAI Registration online with Go Legal India. We help you renew the certificate and file your application on the FOSCOS Portal. Call us for assistance today!",
  },
  "private-limited-company": {
    description:
      "Register a Private Limited Company in India with instant name approval and expert help from IndiaFilings. Start now!",
  },
};
