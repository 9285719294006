import React from "react";
import IMAGE_1 from "../../assets/images/FoodSafetyMitra.webp";
const TopBarHeader = ({ title, subTitle, description }) => {
  return (
    <div className="w-full content-center">
      <h1 className="font-bold text-3xl md:text-5xl text-center md:text-left my-2">
        {title}
      </h1>
      <span className="text-lg md:text-xl text-center md:text-left font-medium">
        {subTitle}
      </span>
      <div className="flex justify-center md:justify-start my-3">
        <div className="rounded h-1 bg-red-500 w-full sm:w-9/12 md:w-11/12" />
      </div>
      <h3 className="text-justify">{description}</h3>
      <div className="flex justify-between flex-row mt-5 gap-5">
        <div className="w-full flex flex-col justify-center">
          <img src={IMAGE_1} alt="Food Safety Mitra" className="w-4/6 sm:w-1/2 self-center" />
        </div>
      </div>
    </div>
  );
};

export default TopBarHeader;
