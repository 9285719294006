import React, { useState } from "react";
import recommended from "../../assets/gifs/recommended.webp";
import SetIndiaBizForm from "./SetIndiaBizForm";
const SetIndiaBizPlans = ({
  plans = [
    {
      id: 1,
      service_type: "fssai-registration",
      plan_name: "Basic 1 Year",
      recommended_plan: 2,
      description: "Annual turnover of the business is less than 12 lakhs",
      service_price: "1499.00",
      discount_price: "999.00",
      discount_title: "₹500 Off",
      what_they_ll_get:
        '[\n    "Application Preparation",\n    "Application Filing",\n    "1 Year Basic License",\n    "LEDGERS Platform"\n]',
      other_fees: "Including 18% GST (Including Govt Fee)",
      gst_percentage: "0.18",
    },
  ],
  title = "",
}) => {
  const [enableForm, setEnableForm] = useState(false);
  const [planChoose, setPlanChoose] = useState({});
  const onPressPLan = (item) => {
    setPlanChoose(item);
    setEnableForm((e) => !e);
  };
  return (
    <>
      <section className="px-5 sm:px-10 md:px-20 bg-red-50">
        <h2 className="font-semibold text-center text-2xl md:text-4xl py-7">
          {title}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 pb-10">
          {plans.map((plan, planIndex) => (
            <div
              className={`flex flex-col justify-between rounded-lg bg-white  border border-red-600 p-2 md:p-3 shadow-md ${
                plan.id == plan.recommended_plan ? `mt-3 md:mt-0 ` : ``
              }`}
            >
              {plan.id == plan.recommended_plan && (
                <div className="mt-3 relative">
                  <img
                    src={recommended}
                    alt="recommended"
                    className="w-2/6 sm:w-1/6 md:w-2/6 absolute bottom-px p-0"
                    style={{}}
                  />
                </div>
              )}
              <h3 className="text-xl md:text-2xl text-center font-bold mb-5">
                {plan.plan_name}
              </h3>
              <h3 className="text-sm sm:text-base text-center font-normal mb-4">
                {plan.description}
              </h3>
              <h3 className="text-sm text-center font-extrabold"></h3>
              <h3 className="text-center flex items-center justify-center gap-2">
                <span className="line-through text-sm ">
                  ₹ {plan.service_price}/-
                </span>
                <span className="text-3xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-red-600 to-red-900">
                  ₹ {plan.discount_price}/-
                </span>
              </h3>
              <p className="text-center text-xs text-black opacity-30 mb-4">
                {plan.other_fees}
              </p>
              <button
                onClick={() => onPressPLan(plan)}
                className="font-bold rounded-lg text-red-600 hover:text-white py-2 mt-2 w-3/4 sm:w-3/6 md:w-full self-center border border-red-600 hover:bg-red-600"
              >
                Choose
              </button>
            </div>
          ))}
        </div>
      </section>
      {enableForm ? (
        <div className="fixed inset-0 z-50 flex items-center">
          <SetIndiaBizForm
            formTitle={"Consult with an Expert!"}
            customClass={`flex justify-center h-full bg-red-100/20 items-center w-full backdrop-blur-sm overscroll-none`}
            // justify-items-center
            customContainerClass={`rounded-lg border-2 w-full my-5 md:my-0 mx-2 sm:mx-28 md:mx-96 p-3 md:p-5 bg-white shadow-md`}
            hasCrossButton
            plan={planChoose}
            onClickCrossButton={() => {
              setEnableForm((e) => !e);
              setPlanChoose({});
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default SetIndiaBizPlans;
