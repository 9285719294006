import React, { useEffect, useRef, useState } from "react";
import themeStyle from "../../styles/theme.style";
import {
  FaEnvelope,
  FaLocationArrow,
  FaPhoneAlt,
  FaUser,
} from "react-icons/fa";
import { MdEmail, MdSubject } from "react-icons/md";
import ContactUsImg from "../../assets/images/contact-us.webp";
import foodVegBg from "../../assets/images/contactUs.webp";
import { API_END_POINT, companyName, isInDev } from "../../Config/config";
import LoadingGif from "../../assets/images/loading.gif";
import { updateMetaDescription } from "../../Config/functions";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: isInDev ? "Ashif" : "",
    phone: isInDev ? "8825870294" : "",
    email: isInDev ? "contact@ashifsadiq.in" : "",
    subject: isInDev ? "My Message" : "",
    message: isInDev ? "Test" : "",
  });
  const fromRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitMessage, setFormSubmitMessage] = useState(null);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const submitForm = async (e) => {
    setIsLoading(true);
    e.preventDefault(); // Prevent default form submission
    let API = `${API_END_POINT}Ticket/addContactForm.php?`;
    Object.keys(formData).map((item) => {
      API += item + "=" + encodeURI(formData[item]) + "&";
    });
    const response = await fetch(API);
    const resJson = await response.json();
    setTimeout(() => {
      if (resJson.success) {
        setIsLoading(false);
        setFormSubmitMessage(resJson.message);
        setFormData({
          name: isInDev ? "Ashif" : "",
          phone: isInDev ? "8825870294" : "",
          email: isInDev ? "" : "",
          subject: isInDev ? "My Message" : "",
          message: isInDev ? "Test" : "",
        });
      }
    }, 2000);
  };
  useEffect(() => {
    setTimeout(() => {
      fromRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, 1000);
    document.title = "Contact US | " + companyName;
    updateMetaDescription("Contact US | " + companyName)
  }, []);
  return (
    <div>

      <div className="flex flex-wrap lg:flex-nowrap md:p-16 gap-8">
        <div className="lg:w-[30%] w-full shadow-lg p-8 relative rounded-lg overflow-hiddenn">
          <h1
            style={{ color: themeStyle.COLOR_RED }}
            className="font-bold text-2xl lg:contact-title"
          >
            Contact us
          </h1>
          <div>
            <div className="flex items-center space-x-2 my-4">
              <FaLocationArrow size={16} color={themeStyle.COLOR_RED} />
              <span className="text-black text-sm">
                33H/3 Mominpore Road, Kolkata, West Bengal - 700023
              </span>
            </div>
            <div className="flex items-center space-x-2 my-4">
              <FaPhoneAlt size={16} color={themeStyle.COLOR_RED} />
              <a href="tel:+917439775535">
                <span className="text-black text-sm">+91 74397 75535</span>
              </a>
            </div>
            <div className="flex items-center space-x-2">
              <FaEnvelope size={16} color={themeStyle.COLOR_RED} />
              <a href="mailto:info@golegalindia.com">
                <span className="text-black text-sm">
                  info@golegalindia.com
                </span>
              </a>
            </div>
          </div>
          <div className="mt-4 flex justify-center">
            <img height={400} width={400} src={ContactUsImg} alt="contact us" />
          </div>
        </div>
        {!formSubmitMessage ? (
          <div className="lg:w-[70%] w-full shadow-lg p-8 relative rounded-lg overflow-hidden">
            <h1
              style={{ color: themeStyle.COLOR_RED }}
              className="mb-3 font-bold text-2xl lg:contact-title text-center"
            >
              Get In Touch
            </h1>
            <form onSubmit={submitForm} key={formData} ref={fromRef}>
              <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center ps-2 pointer-events-none">
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 0a8.949 8.949 0 0 0 4.951-1.488A3.987 3.987 0 0 0 13 16h-2a3.987 3.987 0 0 0-3.951 3.512A8.948 8.948 0 0 0 12 21Zm3-11a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full ps-10 p-2.5"
                    placeholder="Your Name"
                  />
                </div>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center ps-2 pointer-events-none">
                    <svg
                      className="w-6 h-6"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M18.427 14.768 17.2 13.542a1.733 1.733 0 0 0-2.45 0l-.613.613a1.732 1.732 0 0 1-2.45 0l-1.838-1.84a1.735 1.735 0 0 1 0-2.452l.612-.613a1.735 1.735 0 0 0 0-2.452L9.237 5.572a1.6 1.6 0 0 0-2.45 0c-3.223 3.2-1.702 6.896 1.519 10.117 3.22 3.221 6.914 4.745 10.12 1.535a1.601 1.601 0 0 0 0-2.456Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="number"
                    id="phone"
                    required
                    value={formData.phone}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full ps-10 p-2.5"
                    placeholder="Phone"
                  />
                </div>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center ps-2 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </div>
                  <input
                    type="email"
                    id="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full ps-10 p-2.5"
                    placeholder="Your Email"
                  />
                </div>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center ps-2 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="subject"
                    required
                    value={formData.subject}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full ps-10 p-2.5"
                    placeholder="Subject"
                  />
                </div>
              </div>
              <textarea
                value={formData.message}
                id="message"
                required
                onChange={handleChange}
                placeholder="Message*"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 mt-4"
                rows="4"
                cols="50"
              ></textarea>
              <button
                style={{ backgroundColor: themeStyle.COLOR_RED }}
                type="submit"
                className="w-full form-button p-3 text-sm font-bold text-white mt-5"
              >
                {isLoading ? (
                  <img
                    src={LoadingGif}
                    width={20}
                    className="img-responsive center-block"
                    style={{
                      margin: "auto",
                    }}
                  />
                ) : (
                  "Send Message"
                )}
              </button>
            </form>
          </div>
        ) : (
          <div className="lg:w-[70%] w-full shadow-lg p-8 relative rounded-lg overflow-hidden grid grid-cols-1">
            <div className="content-center">
              <h1
                style={{ color: themeStyle.COLOR_RED }}
                className="font-bold text-2xl lg:contact-title text-center"
                dangerouslySetInnerHTML={{ __html: formSubmitMessage }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactUs;
