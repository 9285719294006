import React from 'react'
import themeStyle from '../../styles/theme.style';
import { SiGoogleforms } from 'react-icons/si';
import { RiSecurePaymentLine } from 'react-icons/ri';
import { FaAddressCard, FaUserTie } from 'react-icons/fa';
import { BiSolidDashboard } from 'react-icons/bi';
import { FaMoneyBillTrendUp } from 'react-icons/fa6';
import { MdOutlineAccountTree } from 'react-icons/md';
import { GiPocketWatch } from 'react-icons/gi';

function RegistrationProcedure() {

    const procedure = [
        {
            icon: <BiSolidDashboard size={40} color={themeStyle.COLOR_RED} />,
            title: 'Easy-to-Use Dashboard',
            description: 'Complete the entire process and chat with us from your dashboard!',
        },
        {
            icon:<FaMoneyBillTrendUp size={40} color={themeStyle.COLOR_RED}/>,
            title: 'Professional Services at Affordable Prices',
            description: 'Usually, “professional” equals “expensive,” but not at Go Legal India!',
        },
        {
            icon:<MdOutlineAccountTree size={40} color={themeStyle.COLOR_RED}/>, 
            title: '25+ Experts with 10+ Years of Experience',
            description: 'We have team of experts to help you through the process.',
        },
        {
            icon:<GiPocketWatch size={40} color={themeStyle.COLOR_RED}/>, 
            title: 'Quick Support',
            description: 'All your queries answered within 24 hours',
        },

    ];

    const ProcedureCard = ({ icons, title, description }) => {
        return (
            <div className="bg-white rounded-lg shadow-xl p-6">
                <div className="text-4xl mb-4">
                    {icons}
                </div>
                <h3 className="text-xl font-bold mb-3">{title}</h3>
                <p className="text-gray-700">{description}</p>
            </div>
        );
    };

    return (
        <div className="px-6 md:px-10 py-16  w-full">
            <div className='my-5 lg:mx-20 md:mx-16 mx-2'>
                <h2 className='text-black text-4xl font-bold mb-3'>Reasons Why Customers Love Us</h2>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-5 md:gap-y-16 gap-y-10 py-10'>
                    {procedure.map((license, index) => (
                        <ProcedureCard key={index} icons={license.icon} title={license.title} description={license.description} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default RegistrationProcedure