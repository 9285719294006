import React from "react";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaLocationArrow,
  FaPhoneAlt,
} from "react-icons/fa";
import themeStyle from "../styles/theme.style";
import { Link, useLocation } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import { companyName, registeredCompanyName } from "../Config/config";
import {
  isPageEnabled,
  navTitles,
  pagePaths,
  serviceTitles,
} from "../Config/pagePaths";

const Footer = () => {
  const location = useLocation();
  return (
    !location.pathname.includes(pagePaths.payment) &&
    !location.pathname.includes(pagePaths.choosePlan) &&
    !location.pathname.includes(pagePaths.receipt) && (
      <footer className="bg-gray-900 text-white">
        <div className="lg:px-20 py-10 px-4">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mb-8">
              {Object.keys(serviceTitles).map(
                (ServiceTitleItem, ServiceTitleIndex) => (
                  <div key={ServiceTitleIndex}>
                    <h3 className="text-xl font-bold mb-5">
                      {ServiceTitleItem}
                    </h3>
                    <ul>
                      {serviceTitles[ServiceTitleItem].map(
                        (service, ServiceIndex) => (
                          <Link to={`/${service}`} key={ServiceIndex}>
                            <li className="pb-2">
                              <span href="#" className="hover:text-red-500">
                                {
                                  navTitles[
                                    Object.keys(pagePaths).find(
                                      (key) => pagePaths[key] === service
                                    )
                                  ]
                                }
                              </span>
                            </li>
                          </Link>
                        )
                      )}
                    </ul>
                  </div>
                )
              )}
              <div>
                <h3 className="text-xl font-bold mb-5">Important Links</h3>
                <ul>
                  <Link to={`/${pagePaths.aboutUs}`}>
                    <li className="pb-2">
                      <span href="#" className="hover:text-red-500">
                        About Us
                      </span>
                    </li>
                  </Link>
                  {/* <Link to={"/sample-certificate"}>
                  <li className="pb-2">
                    <a href="#" className="hover:text-red-500">Sample certificate</a>
                  </li>
                </Link> */}
                  <Link to={`/${pagePaths.termsConditions}`}>
                    <li className="pb-2">
                      <span href="#" className="hover:text-red-500">
                        Terms & Conditions
                      </span>
                    </li>
                  </Link>
                  <Link to={`/${pagePaths.privacyPolicy}`}>
                    <li className="pb-2">
                      <span href="#" className="hover:text-red-500">
                        Privacy Policy
                      </span>
                    </li>
                  </Link>
                  <Link to={`/${pagePaths.refundPolicy}`}>
                    <li className="pb-2">
                      <span href="#" className="hover:text-red-500">
                        Refund Policy
                      </span>
                    </li>
                  </Link>
                  <Link to={`/${pagePaths.disclaimer}`}>
                    <li className="pb-2">
                      <span href="#" className="hover:text-red-500">
                        Disclaimer
                      </span>
                    </li>
                  </Link>
                  {/* <Link to={`/${pagePaths.blog}`}>
                    <li className="pb-2">
                      <span href="#" className="hover:text-red-500">
                        Blog
                      </span>
                    </li>
                  </Link> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-16 container mx-auto ">
            {/* bg-white/30 backdrop-blur-md rounded-lg py-2 */}
            <div className="px-4 mt-8 md:mt-0">
              <Link to={"/contact-us"}>
                <h2
                  style={{ color: themeStyle.COLOR_WHITE }}
                  className="font-bold text-2xl lg:contact-title"
                >
                  Contact us
                </h2>
              </Link>
              {/* <h1 style={{ color: themeStyle.COLOR_WHITE }} className='font-bold text-2xl lg:contact-title'>Contact us</h1> */}
              <div>
                <div className="flex items-center space-x-2 my-4">
                  <FaLocationArrow size={16} color={themeStyle.COLOR_RED} />
                  <span className="text-white text-sm">
                    33H/3 Mominpore Road, Kolkata, West Bengal - 700023
                  </span>
                </div>
                <div className="flex items-center space-x-2 my-4">
                  <FaPhoneAlt size={16} color={themeStyle.COLOR_RED} />
                  <a href="tel:+917439775535">
                    <span className="text-white text-sm">+91 74397 75535</span>
                  </a>
                </div>
                <div className="flex items-center space-x-2">
                  <FaEnvelope size={16} color={themeStyle.COLOR_RED} />
                  <a href="mailto:info@golegalindia.com">
                    <span className="text-white text-sm">
                      info@golegalindia.com
                    </span>
                  </a>
                </div>
              </div>
            </div>

            <div className="px-4 mt-8 md:mt-0">
              <h2 className="text-xl font-bold mb-5">Follow us on</h2>
              <div className="mx-auto flex space-x-4">
                <a
                  href="https://www.facebook.com/golegalindiaofficial/"
                  target="_blank"
                  className="hover:text-blue-600 transition-colors duration-300"
                >
                  <FaFacebook size={26} />
                </a>
                <a
                  href="https://www.instagram.com/golegalindiaofficial/"
                  target="_blank"
                  className="hover:text-pink-600 transition-colors duration-300"
                >
                  <FaInstagram size={26} />
                </a>
                <a
                  href="https://x.com/golegalindia_"
                  target="_blank"
                  className="hover:text-blue-400 transition-colors duration-300"
                >
                  <FaXTwitter size={26} />
                </a>
              </div>
            </div>
          </div>

          <div className="container mx-auto mt-8 px-4 mb-4">
            <p className="text-sm text-white pb-5 font-bold">
              Go Legal India is a part of <span className="" >{registeredCompanyName}</span>.
              Which is registered under the Companies Act, 2013.<br/>CIN : U69100WB2024PTC274424
            </p>
            <p className="text-lg text-white font-bold">Disclaimer:</p>
            <p className="text-sm text-white">
              This is not a Government run Website and the form is not the
              actual registration form, it is just to collect information from
              our clients so that our expert can easily understand their
              business or needs. By proceeding forward with this website you are
              aware that we are a private company managing this website and
              providing assistance based on the request from our customers and
              the fee collected in this website is a consultancy fee.
            </p>
          </div>
        </div>

        <div className="bg-gray-500 text-white py-4">
          <div className="container mx-auto text-center">
            <p
              className="text-xs"
              dangerouslySetInnerHTML={{
                __html: `&copy; Copyright ${companyName}. All Rights Reserved${
                  location.pathname == "/about-us"
                    ? "<br/>Design and Developed By <u><a href='https://ashifsadiq.in' target='_blank'>Ashif Sadiq</a></u>"
                    : ""
                }`,
              }}
            ></p>
          </div>
        </div>
      </footer>
    )
  );
};

export default Footer;
