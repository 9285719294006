import React from "react";
import TopBannerSection from "../../components/TopBannerSection";
import ProcessRoundCircleSection from "../../components/ProcessRoundCircleSection";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { HiDocumentArrowDown } from "react-icons/hi2";
import { FaCheck } from "react-icons/fa";
import themeStyle from "../../styles/theme.style";
import OverviewContent from "../../components/OverviewContent";
import OverViewImage from "../../assets/images/IncomeTaxFiling.webp";
import {
  ITRFilingBenefit,
  ITRFilingDocumentsRequired,
  ITRFilingOverview,
  ITRFilingRequirement,
  ITRFilingTypes,
} from "./ITR/ItrContent";
import CustomTabs from "../../components/CustomTabs";
import PageFaq from "../../components/PageFaq";
const IncomeTaxFiling = () => {
  const process = [
    {
      title: "Reach out to our Experts",
      icon: <TfiHeadphoneAlt size={46} color={themeStyle.COLOR_RED} />,
      description:
        "We have the best business experts who can resolve all your queries",
    },
    {
      title: "Provide all the required documents",
      icon: <HiDocumentArrowDown size={46} color={themeStyle.COLOR_RED} />,
      description:
        "Provide all the documents. Our team will initiate the paperwork on your behalf",
    },
    {
      title: "Get your GST Registration done",
      icon: <FaCheck size={46} color={themeStyle.COLOR_RED} />,
      description:
        "Income tax return filing online is completed and the certificates will be sent to you.",
    },
  ];
  const tabs = [
    { title: "Benefit", content: <ITRFilingBenefit /> },
    { title: "Requirement", content: <ITRFilingRequirement /> },
    { title: "Types", content: <ITRFilingTypes /> },
    {
      title: "Document required",
      content: <ITRFilingDocumentsRequired />,
    },
  ];
  const faqs = [
    {
      title:
        "Is it mandatory to define the nature of employment while filing of return?",
      content: `Yes, it is mandatory to define the nature of employment while filing of return from the following: - <br/><br/>(a)Central Government Employee<br/>(b) State Government Employee<br/>(c) Employee of Public Sector Enterprise (whether Central or State Government)<br/>(d) Pensioners (CG/SG/PSU/OTHER)<br/>(e) Employee of Private Sector concern<br/>(f) Not applicable (in case of family pension income)`,
    },
    {
      title:
        "What precautions should I take while filing the return of income?",
      content: `<ol class="list-disc">
<li class="ml-10">Carefully select the tax regime.</li>
<li class="ml-10">Download AIS and Form 26AS and check the actual TDS / TCS / tax paid. If you see any discrepancy, you should reconcile it with the Employer / Tax Deductor / Bank.</li>
<li class="ml-10">Compile and carefully study the documents to be referred to when filing your ITR, like bank statement / passbook, interest certificates, receipts to claim exemptions or deductions, Form 16, Form 26AS (Annual Information Statement), investment proofs, etc.</li>
<li class="ml-10">Ensure details like PAN, permanent address, contact details, bank account details, etc. are correct in the pre-filled data.</li>
<li class="ml-10">Identify the correct return for you (from ITR-1 to ITR-7). Provide all the details in the return such as total income, deductions (if any), interest (if any), taxes paid / collected (if any), etc. No documents are to be attached along with ITR-1.</li>
<li class="ml-10">e-File the return of income on or before the due date. The consequences of delay in filing returns include late filing fees, losses not getting carried forward, deductions and exemptions not being available.</li>
<li class="ml-10">After e-Filing the return, e-Verify it. If you want to manually verify your return, send the signed physical copy of ITR-V Acknowledgement (by speed post) within appropriate timelines of filing the return to Centralized Processing Center, Income Tax Department, Bengaluru 560500 (Karnataka).</li>
</ol>`,
    },
  ];
  return (
    <>
      <section>
        <TopBannerSection location={"income-tax-filing"} />
      </section>
      <section>
        <ProcessRoundCircleSection
          heading={'How To Do Your GST Registration Through "GLI"'}
          process={process}
        />
      </section>
      <section>
        <OverviewContent
          OverViewImage={OverViewImage}
          content={<ITRFilingOverview />}
        />
      </section>
      <section>
        <CustomTabs tabs={tabs} />
      </section>
      <section>
        <PageFaq list={faqs} />
      </section>
    </>
  );
};

export default IncomeTaxFiling;
