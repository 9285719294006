import LocalBusiness from "./LocalBusiness";
import Ratings from "./Ratings";

export const LocalBusinessSiteSchema = () => (
  <script type="application/ld+json">{JSON.stringify(LocalBusiness)}</script>
);
export const RatingsSiteSchema = () => (
  <script type="application/ld+json">{JSON.stringify(Ratings)}</script>
);

export default {
  LocalBusiness,
  Ratings,
};
